var layoutMode = "desktop";
$(function () {
  //スマホかチェック
  if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
    //ロード時もチェック
    orientCheck();
    window.addEventListener("orientationchange", function () {
      /* 向き切り替え時の処理 */
      orientCheck();
    });
  } else {
    //スマホ以外
  }
  // 初回チェック
  checkBreakPoint();
});
// リサイズの監視
window.addEventListener('resize', checkBreakPoint);

//スマホの横向きチェック
function orientCheck() {
  //正面設定と現在の向きを取得
  if (window.innerHeight > window.innerWidth) {
    /* 縦画面時の処理 */
    if ($('body').hasClass('oriented')) {
      $('body').removeClass('oriented');
      //１回だけリロードする
      if (window.name != "mobileWindow") {
        //現在表示されているページをリロードする
        location.reload();
        window.name = "mobileWindow";
      }
    } else {
      window.name = "mobileWindow";
    }
  } else {
    /* 横画面時の処理 */
    $('body').addClass('oriented');
    //１回だけリロードする
    if (window.name != "orientedWindow") {
      // 現在表示されているページをリロードする
      location.reload();
      window.name = "orientedWindow";
    }
  }
}

//ブレークポイント処理
function checkBreakPoint() {
  var width = window.innerWidth;
  if (width <= 480) {
    if (layoutMode != "mobile") {
      contentTitleConv();//トップページ専用のタイトル変換
      mobileLayout();
    }
  } else if (width <= 767) {
    //スマホのみ
    if (layoutMode != "mobile") mobileLayout();
    window.name = "orientedWindow";
  } else if (width <= 953) {
    //タブレットのみ
    if (layoutMode != "mobile") mobileLayout();
    window.name = "orientedWindow";
  } else {
    //横向きチェック
    // PC向け
    //モバイルモードからリサイズされたら
    if (layoutMode != "desktop") {
      //モードを切り替えて
      layoutMode = "desktop";
      //１回だけリロードする
      if (window.name != "desktopWindow") {
        // 現在表示されているページをリロードする
        location.reload();
        window.name = "desktopWindow";
      }
    }
  }
}
//---

//モバイルレイアウト
function mobileLayout() {
  gnavSp();//モバイルナビゲーション
  pageScroll();//アンカーリンクスクロール
  pageTopFadeIn();//ページトップボタンのフェード処理
  pTitleConverter();//pタグ画像タイトル処理
  bannerConverter();//サイドバナー処理
  subnaviConverter();//#subnaviの処理
  h1ImgConv();//本文内のh1画像対策
  pLeadConv();
  layoutMode = "mobile";
}

//アンカーリンクスクロール
function pageScroll() {
  $('a.scroll').click(function () {
    var speed = 400,
      href = $(this).attr('href'),
      target = $(href == "#" || href == "" ? 'html' : href),
      position = target.offset().top;
    $('body,html').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });
}
//ページトップボタン処理
function pageTopFadeIn() {
  $(window).scroll(function () {
    var $pagetop = $('.btn-pagetop'),
      Pos = 100,
      ScrollPos = $(window).scrollTop();
    if (ScrollPos >= Pos) {
      $pagetop.fadeIn();
    } else {
      $pagetop.fadeOut();
    }
  });
}
//メニューの開閉
function gnavSp() {
  var $trigger = $('.gnav-trigger'),
    $nav = $('.groval-nav'),
    $close = $('.gnav-close');
  $trigger.click(function () {
    $nav.slideToggle();
    $(this).toggleClass('trigger-open');
  });
  $close.click(function () {
    $nav.slideUp();
    $trigger.removeClass('trigger-open');
  });
}
//スマホ時にタイトルが画像になっているやつをaltテキストへ入れ替える
function pTitleConverter() {
  if ($('p.title').length > 0) {
    $('p.title').each(function () {
      //alt取得
      var this_alt = $(this).find('img').attr('alt');
      switch (this_alt) {
        case '新着情報':
          $(this).find('img').parent().html('<a href="news/index.html">' + this_alt + '</a>');
          $(this).addClass('mobile');
          $(this).addClass('news');
          break;
        case '一般の方へ':
          $(this).find('img').parent().html('<a href="information/general/index.html">' + this_alt + '</a>');
          $(this).addClass('mobile');
          $(this).addClass('oshirase');
          break;
        case '医療・研究関係者の方へ':
          $(this).find('img').parent().html('<a href="information/medical_personnel/index.html">' + this_alt + '</a>');
          $(this).addClass('mobile');
          $(this).addClass('oshirase');
          break;
        case 'トピックス':
          $(this).find('img').parent().html('<a href="/press_release/books/index.html">' + this_alt + '</a>');
          $(this).addClass('mobile');
          $(this).addClass('oshirase');
          break;
        case '':
          //altが空のときの処理
          break;
        default:
          //なんかしら値がある時の処理
          $(this).find('img').parent().html(this_alt);
          $(this).addClass('mobile');
      }
      //switchHatへの対処
      if ($(this).hasClass('switchHat')) {
        $(this).next().removeClass('switchDetail');
        $(this).removeClass('switchHat');
      }
    });
  }
};

//トップページ専用のタイトル変換
function contentTitleConv() {
  if ($('body#top').length > 0 && $('p.content_ttl').length > 0) {
    $('p.content_ttl').each(function () {
      //alt取得
      var this_alt = $(this).find('img').attr('alt');
      switch (this_alt) {
        case '研究':
          $(this).find('img').parent().html(this_alt);
          $(this).addClass('mobile');
          $(this).addClass('kenkyu');
          break;
        case '診療':
          $(this).find('img').parent().html(this_alt);
          $(this).addClass('mobile');
          $(this).addClass('shinryou');
          break;
        case '教育':
          $(this).find('img').parent().html(this_alt);
          $(this).addClass('mobile');
          $(this).addClass('kyouiku');
          break;
        case '':
          //altが空のときの処理
          break;
        default:
          //なんかしら値がある時の処理
          $(this).find('img').parent().html(this_alt);
          $(this).addClass('mobile');
      }
    });
  }
}
//バナーリストを画像からaltテキストへ変更する
function bannerConverter() {
  if ($('#banner').length > 0) {
    $('#banner').find('li').each(function () {
      //alt取得
      var this_alt = $(this).find('img').attr('alt');
      if (this_alt != '') {
        $(this).find('img').parent().html(this_alt);
        $(this).addClass('mobile');
      } else {

      }
    });
  }
}
//サイド #subnavi の処理
function subnaviConverter() {
  if ($('#subnavi').length > 0) {
    //pタグ
    var this_alt = $('#subnavi').find('p img').attr('alt');
    $('#subnavi').find('p img').parent().html(this_alt);
    $('#subnavi').find('p').addClass('mobile');//一考の余地あり
    $('#subnavi div >ul >li').each(function () {
      //alt取得
      var this_alt = $(this).find('img').attr('alt');
      if (this_alt != '') {
        $(this).find('img').parent().html(this_alt);
        $(this).addClass('mobile');
      } else {

      }
    });
  }
}
//h1が画像の場合の処理
function h1ImgConv() {
  if ($('#main_area h1 img').length > 0) {
    var this_alt = $('#main_area h1 img').attr('alt');
    $('#main_area h1 img').parent().html(this_alt);
    $('#main_area h1').addClass('mobile');
  }
  //h1が特殊なpress_release-policyのindex
  if ($('body#press_release-policy').length > 0) {
    var geth1 = $('#main_area h1');
    var h1_title_text = $('.h1_title').text();
    geth1.addClass('mobile');
    geth1.html(h1_title_text);
  }
}
//p.leadの画像対策
function pLeadConv() {
  if ($('p.lead img').length > 0) {
    var this_alt = $('p.lead img').attr('alt');
    $('p.lead img').parent().html(this_alt);
    $('p.lead').addClass('mobile');
  }
}

//タブレット端末でのみ、PC向けデザインの最小幅をviewportの最小幅に設定
/*
(function () {
  var ua = navigator.userAgent

  var sp = ua.indexOf('iPhone') > -1 ||
    (ua.indexOf('Android') > -1 && ua.indexOf('Mobile') > -1)

  var tab = !sp && (
    ua.indexOf('iPad') > -1 ||
    (ua.indexOf('Macintosh') > -1 && 'ontouchend' in document) ||
    ua.indexOf('Android') > -1
  )

  if (tab) new ViewportExtra(954)
})();
*/